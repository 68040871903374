import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    FormGroup,
    Input,
    Label,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from 'mdbreact';

// Import datatable css
import "../Tables/datatables.scss";
import Moment from "moment"
//url
import url from "../../helpers/apiUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddSupportCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "Customer Support", link: "customerservice" },
                { title: "Add Category", link: "#" },
            ],
            modal_add: false,
            modal_edit: false,
            category: [],
            cat: [],
            id: '',
            category_name: '',
            category_description: '',
            category_status: '',
            arr: [],
            success_confirm: false,
            alert_confirm: false,
            dynamic_title: "",
            temp: false,
            dynamic_description: "",
            prev_category_description: '',
            prev_category_name: '',
            prev_category_status: '',
            createCat: false,
            updateCat: false
        }
        this.deletesupportCategory = this.deletesupportCategory.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
        this.createsupportCategory = this.createsupportCategory.bind(this);
        this.viewsupportCategoryById = this.viewsupportCategoryById.bind(this);
        this.fillCategory = this.fillCategory.bind(this);
        this.updatesupportCategory = this.updatesupportCategory.bind(this);
        this.viewsupportCategory = this.viewsupportCategory.bind(this);
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Customer Support", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }

        this.viewsupportCategory()
    }


    viewsupportCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/support`, requestOptions).then(response => response.json()).then(data => {
            if(data.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            for (let i = 0; i < data.body.length; i++) {
                array.push({
                    id: data.body[i].id,
                    category_name: data.body[i].category_name,
                    category_description: data.body[i].category_description,
                    category_status: data.body[i].category_status,
                    created_at: Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                    updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                    button: <div style={{display: "flex", justifyContent: "center"}}>
                        <Button type="button"
                            onClick={
                                () => this.fillCategory(data.body[i])
                            }
                            style={
                                { marginRight: 10 }
                            }
                            color="primary"
                            id="tooltip_edit_cat_ticket"
                            className="waves-effect waves-light buttons-secondary">
                            <i className="ti-pencil"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="tooltip_edit_cat_ticket">
                                Edit 
                            </UncontrolledTooltip>
                        <Button type="button" color="danger"
                            onClick={
                                () => this.setState({ category_name: data.body[i].category_name, alert_confirm: true, id: data.body[i].id })
                            }
                            className="waves-effect waves-light buttons-secondary"
                            id="tooltip_delete_cat_ticket"><i className="ti-trash"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="tooltip_delete_cat_ticket">
                            Delete
                        </UncontrolledTooltip>
                    </div>

                })
            }
            this.setState({ category: array })
        }).catch(error => console.log('error', error));

    }

    viewsupportCategoryById() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/support/1`, requestOptions).then(response => response.text()).then(result => console.log(result)).catch(error => console.log('error', error));

    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    deletesupportCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/support/` + this.state.id, requestOptions).then(response => response.json())
        .then(result => {
            if(result.statusCode==="200"){
                toast(<div className='toast-message-container'>
                <h6>Ticket Category</h6>
                <p>Category deleted successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }else{
                toast(<div className='toast-message-container'>
                <h6>Ticket Category</h6>
                <p>Category could not be deleted.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
            this.setState({
                category: this.state.category.filter(cat => cat.id !== this.state.id)
            })
        }).catch(error => console.log('error', error));

    }

    createsupportCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "category_name": this.state.category_name, "category_description": this.state.category_description, "category_status": this.state.category_status });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/category/support/create`, requestOptions).then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {
                    this.viewsupportCategory()
                    this.tog_add()
                    this.setState({
                        category_name: '',
                        category_description: '',
                        category_status: '',
                        //createCat: true
                    })
                    // setTimeout(() => {
                    //     this.setState({
                    //         createCat: false
                    //     })
                    // }, 3000)
                    toast(<div className='toast-message-container'>
                    <h6>Ticket Category</h6>
                    <p>Ticket category created successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                }
                if (result.body === "Support Category already exists") {
                    // this.setState({
                    //     temp: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         temp: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Ticket Category</h6>
                    <p>This category already exists.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }

            }).catch(error => console.log('error', error));

    }

    fillCategory = (cat) => {
        this.setState({ 
            id: cat.id, 
            category_name: cat.category_name, 
            category_description: cat.category_description, 
            category_status: cat.category_status,
            prev_category_name: cat.category_name,
            prev_category_status: cat.category_status,
            prev_category_description: cat.category_description
        })
        this.tog_edit();
    }

    updatesupportCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "category_name": this.state.category_name, "category_description": this.state.category_description, "category_status": this.state.category_status });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/category/support/create/` + this.state.id, requestOptions).then(response => response.json()).then(result => {
            this.viewsupportCategory();
            this.tog_edit()
            this.setState({ id: '', category_name: '', category_description: '', category_status: '', 
            //updateCat:true 
            })
            // setTimeout(() => {
            //     this.setState({
            //         updateCat: false
            //     })
            // }, 3000)
            toast(<div className='toast-message-container'>
            <h6>Ticket Category</h6>
            <p>Category updated successfully.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
         })
        }).catch(error => console.log('error', error));

    }


    tog_add() {
        if (this.state.modal_add === true) {
            this.setState({
                category_name: '',
                category_description: '',
                category_status: ''
            })
        }
        this.setState(prevState => ({
            modal_add: !prevState.modal_add
        }));
    }

    tog_edit() {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }));
        if (this.state.modal_edit !== false) {
            this.setState({
                category_name: '',
                category_description: '',
                category_status: ''
            })
        }
    }


    render() {
        // const {category} = this.state

        const cat = {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Category Name',
                    field: 'category_name',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Category Description',
                    field: 'category_description',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Category Status',
                    field: 'category_status',
                    sort: 'asc',
                    width: 200
                }, {
                    label: 'Created At',
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                }, {
                    label: 'Updated At',
                    field: 'updated_at',
                    sort: 'asc',
                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.category
        };

        return (
            <React.Fragment>
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Category</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete " + this.state.category_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deletesupportCategory();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div>
                    <h4>Support Category</h4>
                </div>

                <Button type="button"
                    onClick={
                        this.tog_add
                    }
                    style={
                        { marginBottom: 10, background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600, margin: "15px" }
                    }
                    color="info"
                    className="waves-effect waves-light">
                    Add Category
                </Button>
                {this.state.createCat ? <Alert color="success">
                    <strong>Category created successfully.</strong>
                </Alert> : null}
                {this.state.updateCat ? <Alert color="success">
                    <strong>Category updated successfully.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped
                                    data={cat} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Adding new category form */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Add Details
                        </ModalHeader>
                        {this.state.temp ? <Alert color="danger">
                            <strong>Duplicate entry for category.</strong>
                        </Alert> : null}
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Category Name (50 characters max.)</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="category_name" type="text" maxLength="50"
                                        value={
                                            this.state.category_name
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Category Description</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="category_description" type="text"
                                        value={
                                            this.state.category_description
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Status</Label>
                                <Col sm="10">
                                    <select style={{width: "120%"}} className="selectFormContainer" name='category_status'
                                        value={
                                            this.state.category_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add
                                }>Close
                            </Button>
                            {
                                this.state.category_name === "" || this.state.category_status === "" || this.state.category_status === "" ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.createsupportCategory()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Add Category
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createsupportCategory()
                                        }
                                        className="waves-effect waves-light">Add Category
                                    </Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>


                {/* Editing category form */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_edit
                    }
                        toggle={
                            this.tog_edit
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Edit Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Category Name (50 characters max.)</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="category_name" type="text" maxLength="50"
                                        value={
                                            this.state.category_name
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Category Description</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="category_description" type="text"
                                        value={
                                            this.state.category_description
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-sm-2 col-form-label">Status</Label>
                                <Col sm="10">
                                    <select className="selectFormContainer" style={{width: "120%"}} name='category_status'
                                        value={
                                            this.state.category_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit
                                }>Close</Button>
                            {
                                this.state.category_name === this.state.prev_category_name && this.state.category_status === this.state.prev_category_status && this.state.category_description === this.state.prev_category_description ?
                                <Button type="button" color="primary" disabled
                                style={{cursor: "default"}}
                                onClick={
                                    () => this.updatesupportCategory()
                                }
                                className="waves-effect waves-light">Save
                                </Button>:
                                <Button type="button" color="primary"
                                onClick={
                                    () => this.updatesupportCategory()
                                }
                                className="waves-effect waves-light">Save</Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(AddSupportCategory);