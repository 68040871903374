import React, {Component} from 'react';
import {connect} from "react-redux";
import {Row, 
    Col, 
    Card, 
    CardBody, 
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Input,
    UncontrolledTooltip
} from "reactstrap";

import { MDBDataTable } from 'mdbreact';
import { Link } from "react-router-dom";
import Moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { RotatingLines } from  'react-loader-spinner'
// Import Action to copy breadcrumb items from local state to redux state
import {setBreadcrumbItems} from "../../store/actions";
import url from "../../helpers/apiUrl"
import productImage from '../../helpers/productImage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ProductReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Product Analytics",
                    link: "#"
                },

            ],
            products: [],
            modal_add: false,
            fromDate: '',
            toDate: '',
            created_at: 0,
            username: '',
            isLoading: true,
            DateAlert: false,
            isLoadingSave: false
        }
        this.viewAllProducts = this.viewAllProducts.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_add2 = this.tog_add2.bind(this);
        this.searchProduct = this.searchProduct.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.searchChangeHandler = this.searchChangeHandler.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.disableToast = this.disableToast.bind(this);
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Product Reports", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
            );
        }
        this.viewAllProducts()
    }

    

    viewAllProducts(){
        this.setState({isLoadingSave : true})
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
           
            
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }            
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "fromDate": fromDate,
            "toDate": toDate
        });
          
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/analytics/products`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            if(result.statusCode === "200"){
                this.setState({isLoadingSave: false})
                if(this.state.created_at === "custom"){
                    this.setState({
                        created_at: 'custom2'
                    })
                }
                    if(this.state.modal_add === true){
                        this.setState(prevState => ({
                            modal_add: false
                        }));
                    } 
                    
                
                for (let i = 0; i < result.body.length; i++) {
                   
                   array.push({
                    _id: result.body[i]._id,
                    vendorType: result.body[i]._source.vendorType,
                    image: result.body[i]._source.image == null ? "No image" : <img alt="Product" style={{height: 130, width: 100}} src={`${productImage}` + result.body[i]._source.image.src}></img>,
                    shopUrl: result.body[i]._source.shopUrl,
                    type: result.body[i]._source.type,
                    name: result.body[i]._source.name,
                    currencySymbol: result.body[i]._source.currencySymbol,
                    purchased: "0",
                    revenue: "£0.00",
                    button: <div>
                         <Link to={{
                            pathname: "/product-details",
                            state: {
                                products: result.body[i],
                                fromDate: this.state.fromDate,
                                toDate: this.state.toDate,
                                created_at: this.state.created_at
                            }
                        }}>
                            <Button type="button"
                                style={
                                    { marginRight: 10 }
                                }
                                color="primary" id="tooltip_edit_product"
                                className="waves-effect waves-light buttons-secondary">
                                <i className="ion ion-md-arrow-round-forward"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_edit_product">
                                View 
                            </UncontrolledTooltip>
                        </Link>
                    </div>
                   })   
                }
            }else{
                this.setState({isLoadingSave: false})
                if(this.state.modal_add === true){
                    this.setState(prevState => ({
                        modal_add: false
                    }));
                } 

                toast(<div className='toast-message-container'>
                <h6>Product Reports</h6>
                <p>Internal server error</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"22px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
            this.setState({ products: array, isLoading: false })
        })
        .catch(error => console.log('error', error));
        
    }

    productReport(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "productId": "llSZH4IB7xXwf0Sy5JRV",
        "fromDate": "2022-02-01",
        "toDate": "2022-08-31"
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/analytics/product-report`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    searchProduct(){
        if (this.state.username === null || this.state.username === "" || this.state.username === undefined) {
            return this.viewAllProducts
        }
        var date = this.state.created_at;
        var fromDate = ''
        var toDate = ''
        if (date === "") {
            fromDate = ""
            toDate = ""
        } else {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": this.state.username,
            "fromDate": fromDate,
            "toDate": toDate
        });


        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/analytics/productbyuser`, requestOptions)
        .then(response => response.json())
        .then(result => {
            var array = []
            if(result.statusCode === "200"){
                for (let i = 0; i < result.body.length; i++) {
                   array.push({
                    vendorType: result.body[i]._source.vendorType,
                    image: result.body[i]._source.image == null ? "No image" : <img alt="Product" style={{height: 130, width: 100}} src={`${productImage}` + result.body[i]._source.image.src}></img>,
                    shopUrl: result.body[i]._source.shopUrl,
                    type: result.body[i]._source.type,
                    name: result.body[i]._source.name,
                    currencySymbol: result.body[i]._source.currencySymbol,
                    title: <div>
                        <h6>{result.body[i]._source.name}</h6><br/>
                        <p>{ result.body[i]._source.vendorType} | {result.body[i]._source.shopUrl}</p>
                    </div>,
                    purchased: "0",
                    revenue: "£0.00",
                    button: <div>
                         <Link to={{
                                    pathname: "/product-details",
                                    state: {
                                        products: result.body[i],
                                        created_at: this.state.created_at,
                                        fromDate: this.state.fromDate,
                                        toDate: this.state.toDate
                                    }
                                }}>
                                    <Button type="button"
                                        
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect buttons-secondary">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </Link>
                    </div>
                   })   
                }
            }
            if (result.statusCode === "200") {
                this.setState({ products: array, isLoading: false })
            }
            else {
                this.setState({products: []})
            }
            
        })
        .catch(error => console.log('error', error));
    }

   

    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add
        }));
       
    }
    tog_add2() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,fromDate:Moment(new Date()).format('YYYY-MM-DD'),toDate:Moment(new Date()).format('YYYY-MM-DD'),created_at:0,DateAlert:false
        }));
        setTimeout(() => {
            this.viewAllProducts()
        }, 1500);
       
        
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value, isLoading: true, username: ''  }) 
        

        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add()
        }
        else{
            setTimeout(() => {
                this.viewAllProducts()
            }, 1500);
        }
        
    }
    searchChangeHandler(e){
        this.setState({ [e.target.name]: e.target.value  }) 
        if(e.target.value === ""){
            this.viewAllProducts()
        }
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }
    keyPress(e){
        if(e.keyCode == 13){
            e.preventDefault();
            
            this.searchProduct();
        }
     }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Product Reports</h6>
            <p>Please select a valid date</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Image',
                    field: 'image',
                    sort: "disabled",
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    width: 150
                },
                {
                    label: 'Shop Name',
                    field: 'vendorType',
                    
                    width: 150
                },
                {
                    label: 'Shop Url',
                    field: 'shopUrl',
                   
                    width: 150
                },
                {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.products
        };
        return (
            <React.Fragment>

                <Row style={{marginBottom: 20}}>
                    <Col xs="8">
                        <Col lg="6">
                            <Form className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <Row>
                                        <Col xs="10">
                                            <span className="fa fa-search"></span>
                                            <Input onChange={this.searchChangeHandler} onKeyDown={this.keyPress} type="text" value={this.state.username} name="username" className="form-control" placeholder="Search by username..." />  
                                        </Col>
                                        <Col xs="2">
                                            {
                                                this.state.username === "" ?
                                                <Button color="primary" disabled onClick={this.searchProduct}>Search</Button>
                                                : <Button color="primary" onClick={this.searchProduct}>Search</Button>
                                            }
                                            
                                        </Col>
                                    </Row>   
                                </div>
                            </Form>
                        </Col>
                    </Col>
                    <Col xs="4">
                        <Col>
                            <select className="form-control" name='created_at'
                                value={
                                    this.state.created_at
                                }
                                onChange={
                                    this.changeHandler
                                }>
                                <option value="0">Today</option>
                                <option value="1">Yesterday</option>
                                <option value="7">Last 7 days</option>
                                <option value="30">Last 30 days</option>
                                <option value="180">Last 6 months</option>
                                <option value = "custom" onClick={this.tog_add}>Custom</option>
                                <option value="custom2" hidden >{ this.state.created_at === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                            </select>
                        </Col>
                    </Col>
                </Row>
                
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                            {
                                    this.state.isLoading === true ? 
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <RotatingLines
                                                strokeColor="grey"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="96"
                                                visible={this.state.isLoading}
                                            />
                                        </div>
                                    : <MDBDataTable responsive bordered striped
                                    data={data}
                                />
                                }
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                           
                            <Row>
                            
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllProducts
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate))  || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllProducts
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewAllProducts
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, {setBreadcrumbItems})(ProductReport);