import React, {Component} from 'react';
import {connect} from "react-redux";
import {setBreadcrumbItems} from "../store/actions";

class NoAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "#"
                }, {
                    title: "No Excess",
                    link: "#"
                },

            ]

        }

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("No Excess", this.state.breadcrumbItems);

    }


    render() {
        // const columns = [
        //     {
        //      name: "name",
        //      label: "Name",
        //      options: {
        //       filter: true,
        //       sort: true,
        //      }
        //     },
        //     {
        //      name: "company",
        //      label: "Company",
        //      options: {
        //       filter: true,
        //       sort: false,
        //      }
        //     },
        //     {
        //      name: "city",
        //      label: "City",
        //      options: {
        //       filter: true,
        //       sort: false,
        //      }
        //     },
        //     {
        //      name: "state",
        //      label: "State",
        //      options: {
        //       filter: true,
        //       sort: false,
        //      }
        //     },
        //    ];
           
        //    const data = [
        //     { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
        //     { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
        //     { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
        //     { name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX" },
        //    ];
           
        //    const options = {
        //      filterType: 'checkbox',
        //    };
        return (
<h1>Hello</h1>
            // <MUIDataTable
            //     title={"Employee List"}
            //     data={data}
            //     columns={columns}
            //     options={options}
            // />
          );
    }
}

export default connect(null, {setBreadcrumbItems})(NoAccess);
