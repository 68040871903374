import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Input,
    Label,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    UncontrolledPopover,
    PopoverBody,
    Alert
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from 'mdbreact';
import check from "../../assets/check.png"
import cross from "../../assets/cross.png"

import url from "../../helpers/apiUrl"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "Subscription", link: "#" },
            ],
            plan: [],
            modal_add_sub: false,
            modal_add_storage: false,
            productId: '',
            role: '',
            active: false,
            success_confirm: false,
            alert_confirm: false,
            stripeId: '',
            paypalId: '',
            currency: '',
            name: '',
            unitLabel: '',
            price: '',
            interval: '',
            created_at: '',
            updated_at: '',
            modal_edit: false,
            shoppableMonthly: [],
            shoppableYearly: [],
            unlimitedMonthly: [],
            unlimitedYearly: [],
            switch1: false,
            monthly: true,
            stripePriceId: '',
            planid: '',
            storageMonthly1: [],
            storageMonthly2: [],
            storageMonthly3: [],
            storageYearly1: [],
            storageYearly2: [],
            storageYearly3: [],
            product_id: '',
            product: [],
            subscription: [],
            storage: [],
            unit_amount: '',
            // interval: '',
            nickname: '',
            temp: false,
            updatePlan: false,
            storagePlan: '',
            subscriptionPlan: '',
            planArchieveAlert: false,
            planArchieveAlertFalse: false,
            planArchiveErrorMessage: '',
            id: ''
        }
        this.getAllPlans = this.getAllPlans.bind(this);
        this.updatePlane = this.updatePlane.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.getAllProducts = this.getAllProducts.bind(this);
        this.getAllStoragePlan = this.getAllStoragePlan.bind(this);
        this.getAllSubscriptionPlan = this.getAllSubscriptionPlan.bind(this);
        this.tog_add_subscription = this.tog_add_subscription.bind(this);
        this.tog_add_storage = this.tog_add_storage.bind(this);
        this.createSubPlan = this.createSubPlan.bind(this);
        this.createStoragePlan = this.createStoragePlan.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.copyClipboard = this.copyClipboard.bind(this);
        this.archivePlan = this.archivePlan.bind(this);
    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            monthly: !this.state.monthly,
            [switchNumber]: !this.state[switchNumber]
        });
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Subscription", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.getAllPlans()
        this.getAllProducts()

    }
    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });
        }
    }

    getAllPlans() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/plans`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                // console.log("abcdef", data.body.hits.hits[0]._source.name)
                var array20GBmonth = []
                var array10GBmonth = []
                var array50GBmonth = []
                var array20GByear = []
                var array10GByear = []
                var array50GByear = []
                var arrayshoppablemonth = []
                var arrayshoppableyear = []
                var arrayunlimitedmonth = []
                var arrayunlimitedyear = []
                for (let i = 0; i < data.body.hits.hits.length; i++) {
                    if(data.body.hits.hits[i]._source.name === "20GB" && data.body.hits.hits[i]._source.interval === "month"){
                        array20GBmonth.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ storageMonthly2: array20GBmonth }) 
                    }
                    
                    if(data.body.hits.hits[i]._source.name === "10GB" && data.body.hits.hits[i]._source.interval === "month"){
                        array10GBmonth.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ storageMonthly1: array10GBmonth }) 
                    }
                    if(data.body.hits.hits[i]._source.name === "50GB" && data.body.hits.hits[i]._source.interval === "month"){
                        array50GBmonth.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ storageMonthly3: array50GBmonth }) 
                    }
                    if(data.body.hits.hits[i]._source.name === "20GB" && data.body.hits.hits[i]._source.interval === "year"){
                        array20GByear.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ storageYearly2: array20GByear }) 
                    }
                    if(data.body.hits.hits[i]._source.name === "10GB" && data.body.hits.hits[i]._source.interval === "year"){
                        array10GByear.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ storageYearly1: array10GByear }) 
                    }
                    if(data.body.hits.hits[i]._source.name === "50GB" && data.body.hits.hits[i]._source.interval === "year"){
                        array50GByear.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ storageYearly3: array50GByear }) 
                    }

                    if(data.body.hits.hits[i]._source.name === "Shoppable" && data.body.hits.hits[i]._source.interval === "month"){
                        arrayshoppablemonth.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ shoppableMonthly: arrayshoppablemonth }) 
                    }
                    if(data.body.hits.hits[i]._source.name === "Shoppable" && data.body.hits.hits[i]._source.interval === "year"){
                        arrayshoppableyear.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ shoppableYearly: arrayshoppableyear }) 
                    }
                    if(data.body.hits.hits[i]._source.name === "Unlimited" && data.body.hits.hits[i]._source.interval === "month"){
                        arrayunlimitedmonth.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ unlimitedMonthly: arrayunlimitedmonth }) 
                    }
                    if(data.body.hits.hits[i]._source.name === "Unlimited" && data.body.hits.hits[i]._source.interval === "year"){
                        arrayunlimitedyear.push({
                            planid: data.body.hits.hits[i]._id,
                            productId: data.body.hits.hits[i]._source.productId,
                            role: data.body.hits.hits[i]._source.role,
                            active: (data.body.hits.hits[i]._source.active).toString(),
                            name: data.body.hits.hits[i]._source.name,
                            unitLabel: data.body.hits.hits[i]._source.unitLabel,
                            price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                            interval: data.body.hits.hits[i]._source.interval,
                            stripePriceId: data.body.hits.hits[i]._source.stripeId,
                            paypalId: data.body.hits.hits[i]._source.paypalId,
                            currency: data.body.hits.hits[i]._source.currency,
                            created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                            updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                            button:
                                <div>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillFaq(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                </div>
    
                        }) 
                        this.setState({ unlimitedYearly: arrayunlimitedyear }) 
                    }
                    
                    // array.push({
                    //     planid: data.body.hits.hits[i]._id,
                    //     productId: data.body.hits.hits[i]._source.productId,
                    //     role: data.body.hits.hits[i]._source.role,
                    //     active: (data.body.hits.hits[i]._source.active).toString(),
                    //     name: data.body.hits.hits[i]._source.name,
                    //     unitLabel: data.body.hits.hits[i]._source.unitLabel,
                    //     price: (data.body.hits.hits[i]._source.price * 0.01).toFixed(2),
                    //     interval: data.body.hits.hits[i]._source.interval,
                    //     stripePriceId: data.body.hits.hits[i]._source.stripeId,
                    //     paypalId: data.body.hits.hits[i]._source.paypalId,
                    //     currency: data.body.hits.hits[i]._source.currency,
                    //     created_at: data.body.hits.hits[i]._source.metadata.createdAt,
                    //     updated_at: data.body.hits.hits[i]._source.metadata.updatedAt,
                    //     button:
                    //         <div>
                    //             <Button type="button"
                    //                 onClick={
                    //                     () => this.fillFaq(data.body[i])
                    //                 }
                    //                 style={
                    //                     { marginRight: 10 }
                    //                 }
                    //                 color="primary"
                    //                 className="waves-effect waves-light">
                    //                 <i className="ion ion-md-arrow-round-forward"></i>
                    //             </Button>
                    //         </div>

                    // })
                }
                console.log(this.state.storageMonthly1[0].name)
                this.setState({
                    // plan: array,
                    // shoppableMonthly: array[8],
                    // shoppableYearly: array[2],
                    // unlimitedYearly: array[3],
                    // unlimitedMonthly: array[7],
                    // storageMonthly1: array[4],
                    // storageMonthly2: array[0],
                    // storageMonthly3: array[9],
                    // storageYearly1: array[1],
                    // storageYearly2: array[5],
                    // storageYearly3: array[6],
                })


            })
            .catch(error => console.log('error', error));
        // console.log("vbn", this.state.shoppableMonthly)
    }

    updatePlane(planid, stripePriceId) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "planId": planid,
            "stripepriceId": stripePriceId
        });
        // console.log(raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/update-plan`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.statusCode === "200") {
                    this.tog_edit()
                    this.getAllPlans()
                    setTimeout(() => {
                        this.getAllPlans()
                    }, 1000)
                    // this.getAllPlans()

                    this.setState({
                        planid: '',
                        stripePriceid: '',
                        //updatePlan: true
                    })
                    // setTimeout(() => {
                    //     this.setState({
                    //         updatePlan: false
                    //     })
                    // }, 3000)
                    toast(<div className='toast-message-container'>
                    <h6>Subscription Plans</h6>
                    <p>Plan updated successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                }

                if(result.body === "Incorrect information provided"){
                    // this.setState({
                    //     temp: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         temp: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Subscription Plans</h6>
                    <p>Incorrect information provided.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }

            })
            .catch(error => console.log('error', error));


    }

    fillPlan() {
        this.tog_edit();
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    tog_edit(planid, stripePriceId) {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit,
            planid: planid,
            stripePriceId: stripePriceId
        }));
        this.getAllPlans()
        this.getAllStoragePlan()
    }

    tog_add_subscription() {
        this.setState({
            id: '',
            nickname: '',
            interval: '',
            unit_amount: ''
        })
        this.setState(prevState => ({
            modal_add_sub: !prevState.modal_add_sub,
        }));
    }

    tog_add_storage() {
        this.setState({
            id: '',
            nickname: '',
            interval: '',
            unit_amount: ''
        })
        this.setState(prevState => ({
            modal_add_storage: !prevState.modal_add_storage,
        }));
    }
    copyClipboard(value) {

        navigator.clipboard.writeText(value);
    }

    getAllProducts() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/stripe/products`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                var arr = []
                for (let i = 0; i < result.body.data.length; i++) {
                    arr.push({
                        product_id: result.body.data[i].id,
                        name: result.body.data[i].name
                    })
                    if(arr[i].name === "OneDash"){
                        this.setState({
                            subscriptionPlan: arr[i].product_id
                        })
                    }
                    if(arr[i].name === "Storage"){
                        this.setState({
                            storagePlan: arr[i].product_id
                        })
                    }
                }
                console.log(arr[0].name, "name")
                
                this.setState({
                    product: arr
                })
                this.getAllStoragePlan()
                this.getAllSubscriptionPlan()
                // console.log(this.state.product[1])
                // console.log(this.state.product[2])
            })
            .catch(error => console.log('error', error));
    }

    getAllStoragePlan() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "product_id": this.state.storagePlan,
            "starting_after": ""
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/stripe/prices`, requestOptions)
            .then(response => response.json())
            .then(result => {
                var arr = []
                for (let i = 0; i < result.body.data.length; i++) {
                    // console.log(result.body.data[i].recurring.interval)
                    arr.push({
                        id: 
                            result.body.data[i].active == true ? 
                            <Row>
                                <Col>
                                    <Label>{result.body.data[i].id}</Label>
                                </Col>
                                <Col style={{display: "flex", justifyContent: "end"}}>
                                    <Button  type="button" onClick={() => this.copyClipboard(result.body.data[i].id)} style={{ marginLeft: 10 }} color="primary" id="popover5" className="waves-effect waves-light mo-mb-2"><i className='fas fa-clipboard-list'></i></Button>
                                    <UncontrolledPopover trigger="focus" target="popover5" placement="right">
                                    <PopoverBody style={{ color: "black" }}>
                                        Copied to clipboard!
                                    </PopoverBody>
                                    </UncontrolledPopover>
                                    <Button type="button" onClick={() => this.setState({id: result.body.data[i].id, alert_confirm: true}) } color="danger" style={{marginLeft: 10}}>Archive</Button>
                                </Col>
                            </Row>
                            :
                            <Row style={{color: '#696969'}}>
                                <Col>
                                    <Label>{result.body.data[i].id}</Label>
                                </Col>
                                <Col style={{display: "flex", justifyContent: "end"}}>
                                    <Button type="button" disabled onClick={() => this.setState({id: result.body.data[i].id, alert_confirm: true}) } color="danger" style={{marginLeft: 10}}>Archived</Button>
                                </Col>
                            </Row>,
                        nickname:  <div style={{color:result.body.data[i].active == false ? "#696969" : ""}}>{result.body.data[i].nickname}</div>,
                        interval: result.body.data[i].recurring.interval,
                        unit_amount: result.body.data[i].unit_amount / 100,
                        price: <div style={{color:result.body.data[i].active == false ? "#696969" : ""}}>{result.body.data[i].unit_amount / 100 + '/' + result.body.data[i].recurring.interval}</div>,
                    })
                }
                this.setState({
                    storage: arr
                })
                // console.log(arr)
            })
            .catch(error => console.log('error', error));
    }

    getAllSubscriptionPlan() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "product_id": this.state.subscriptionPlan,
            "starting_after": ""
        });
        // console.log(this.state.product[2].product_id)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/stripe/prices`, requestOptions)
            .then(response => response.json())
            .then(result => {
                var arr = []
                for (let i = 0; i < result.body.data.length; i++) {
                    arr.push({
                        id:
                        result.body.data[i].active == true ?
                        <Row>
                            <Col>
                                <Label>{result.body.data[i].id}</Label>
                            </Col>
                            <Col style={{display: "flex", justifyContent: "end"}}>
                                <Button type="button" onClick={() => this.copyClipboard(result.body.data[i].id)} style={{ marginLeft: 10 }} color="primary" id="popover5" className="waves-effect waves-light mo-mb-2"><i className='fas fa-clipboard-list'></i></Button>
                                <UncontrolledPopover trigger="focus" target="popover5" placement="right">
                                    <PopoverBody style={{ color: "black" }}>
                                        Copied to clipboard!
                                    </PopoverBody>
                                </UncontrolledPopover>
                                <Button type="button" onClick={() => this.setState({id: result.body.data[i].id, alert_confirm: true}) } color="danger" style={{marginLeft: 10}}>Archive</Button>
                            </Col>
                            
                        </Row>
                        :
                        <Row style={{color: '#696969'}}>
                            <Col>
                                <Label>{result.body.data[i].id}</Label>
                            </Col>
                            <Col style={{display: "flex", justifyContent: "end"}}>
                                <Button type="button" disabled onClick={() => this.setState({id: result.body.data[i].id, alert_confirm: true}) } color="danger" style={{marginLeft: 10}}>Archived</Button>
                            </Col>
                        
                        </Row>,
                        nickname: <div style={{color:result.body.data[i].active == false ? "#696969" : ""}}>{result.body.data[i].nickname}</div>,
                        interval: result.body.data[i].recurring.interval,
                        unit_amount: result.body.data[i].unit_amount / 100,
                        price: <div style={{color:result.body.data[i].active == false ? "#696969" : ""}}>{result.body.data[i].unit_amount / 100 + "/" + result.body.data[i].recurring.interval}</div>,
                    })
                }
                this.setState({
                    subscription: arr
                })
            })
            .catch(error => console.log('error', error));
    }

    archivePlan(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "planId": this.state.id
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/subscriptions/stripe/archive-plan`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.getAllSubscriptionPlan()
                this.getAllStoragePlan()
                // this.setState({
                //     planArchieveAlert: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         planArchieveAlert: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Subscription Plans</h6>
                <p>Plan archived successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }
            if(result.statusCode === "400"){
                //console.log(result.body);
                // this.setState({
                //     planArchieveAlertFalse: true,
                //     planArchiveErrorMessage: result.body
                // })
                // setTimeout(() => {
                //     this.setState({
                //         planArchieveAlertFalse: false
                //     })
                // }, 6000)
                toast(<div className='toast-message-container'>
                <h6>Subscription</h6>
                <p>{result.body}</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    createSubPlan() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "unit_amount": this.state.unit_amount * 100,
            "currency": "USD",
            "interval": this.state.interval,
            "product_id": this.state.subscriptionPlan,
            "nickname": this.state.nickname
        });
        // console.log(raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/stripe/create-plan`, requestOptions)
            .then(response => response.json())
            .then(result => {
                toast(<div className='toast-message-container'>
                <h6>Subscription Plans</h6>
                <p>Plan created successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                this.tog_add_subscription()
                this.getAllSubscriptionPlan()
            })
            .catch(error => console.log('error', error));
    }

    createStoragePlan() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "unit_amount": this.state.unit_amount * 100,
            "currency": "USD",
            "interval": this.state.interval,
            "product_id": this.state.storagePlan,
            "nickname": this.state.nickname
        });
        // console.log(raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/subscriptions/stripe/create-plan`, requestOptions)
            .then(response => response.json())
            .then(result => {
                toast(<div className='toast-message-container'>
                <h6>Subscription Plans</h6>
                <p>Plan created successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
                this.tog_add_storage()
                this.getAllStoragePlan()
            })
            .catch(error => console.log('error', error));
    }



    render() {
        const data = {
            columns: [
                {
                    label: 'Name',
                    field: 'nickname',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Price',
                    field: 'price',
                    sort: 'asc',
                    width: 100
                },
                
                {
                    label: 'Price ID',
                    field: 'id',
                    sort: 'asc',
                    width: 270
                }
            ],
            rows: this.state.storage
        };

        const plans = {
            columns: [
                {
                    label: 'Name',
                    field: 'nickname',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Price',
                    field: 'price',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Price ID',
                    field: 'id',
                    sort: 'asc',
                    width: 270
                }
            ],
            rows: this.state.subscription
        };   
        return (
            <React.Fragment>
                
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Archive Subscription</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{"Are you sure you want to archive this plan?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.archivePlan();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Archive</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <h1>Subscription Details</h1>
                {this.state.updatePlan ? <Alert color="success">
                    <strong>Plan updated successfully.</strong>
                </Alert> : null}
                <div className='custom-control custom-switch align-item-center justify-content-center d-flex'>
                    <p style={{ paddingRight: 45 }}>Monthly</p>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id='customSwitches'
                        checked={this.state.switch1}
                        onChange={this.handleSwitchChange(1)}
                        readOnly
                    />

                    <label className='custom-control-label' htmlFor='customSwitches'>
                        Yearly
                    </label>
                </div>


                {this.state.monthly ?
                    <div>
                        <h5>Subscription</h5>
                        <Row className="align-item-center justify-content-center d-flex">

                            <Col md="6" lg="6" xl="5" className="text-center">
                                <Card>
                                    {
                                        this.state.shoppableMonthly.map(sushmo => (
                                            <CardBody>
                                                <h3 className="card-title">{sushmo.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{sushmo.price}/mo</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(sushmo.planid, sushmo.stripePriceid)}>Edit</Button>
                                                <div style={{marginTop: 25, paddingLeft: 200}}>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> 3 GB allocation of space</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Hotspots: static, dynamic and linked</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Editable overlays</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Tracking and AI based recognition</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Choice of player representation</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Branching by video and audio</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Shoppable video</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Video analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Product analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Support service</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={cross} alt="cross" style={{height: 15, width: 15}} /> Team</p>
                                                </div>
                                            </CardBody>
                                        ))
                                    }
                                    

                                </Card>
                            </Col>
                            <Col md="6" lg="6" xl="5" className="text-center">
                                <Card>
                                    {
                                        this.state.unlimitedMonthly.map(suunmo => (
                                            <CardBody>
                                                <h3 className="card-title">{suunmo.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{suunmo.price}/mo</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(suunmo.planid, suunmo.stripePriceid)}>Edit</Button>
                                                <div style={{marginTop: 25, paddingLeft: 200}}>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Unlimited space</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Hotspots: static, dynamic and linked</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Editable overlays</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Tracking and AI based recognition</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Choice of player representation</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Branching by video and audio</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Shoppable video</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Video analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Product analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Support service</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Team</p>
                                                </div>
                                            </CardBody>
                                        ))
                                    }
                                </Card>
                            </Col>


                        </Row>
                        <h5>Storage</h5>
                        <Row className="align-item-center justify-content-center d-flex">

                            <Col md="4" lg="4" xl="4" className="text-center">
                                <Card>
                                    {
                                       this.state.storageMonthly1.map(stmo1 => (
                                        <CardBody>
                                            <h3 className="card-title">{stmo1.name}</h3><br />
                                            <h5 className="card-subtitle font-14 text-muted">{stmo1.price}/mo</h5><br />
                                            <Button color="primary" onClick={() => this.tog_edit(stmo1.planid, stmo1.stripePriceid)}>Edit</Button>
                                        </CardBody>

                                       ))
                                    }
                                    
                                </Card>
                            </Col>
                            <Col md="4" lg="4" xl="4" className="text-center">
                                <Card>
                                    {
                                        this.state.storageMonthly2.map(stmo2 => (
                                            <CardBody>
                                                <h3 className="card-title">{stmo2.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{stmo2.price}/mo</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(stmo2.planid, stmo2.stripePriceid)}>Edit</Button>
                                            </CardBody>
                                        ))
                                    }
                                    
                                </Card>
                            </Col>
                            <Col md="4" lg="4" xl="4" className="text-center">
                                <Card>
                                    {
                                        this.state.storageMonthly3.map(stmo3 => (
                                            <CardBody>
                                                <h3 className="card-title">{stmo3.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{stmo3.price}/mo</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(stmo3.planid, stmo3.stripePriceid)}>Edit</Button>
                                            </CardBody>
                                        ))
                                    }
                                    
                                </Card>
                            </Col>

                        </Row>
                    </div>
                    :
                    <div>
                        <h5>Subscription</h5>
                        <Row className="align-item-center justify-content-center d-flex">
                            <Col md="6" lg="6" xl="5" className="text-center">
                                <Card>
                                    {
                                        this.state.shoppableYearly.map(sushye => (
                                            <CardBody>
                                                <h3 className="card-title">{sushye.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{sushye.price}/yr</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(sushye.planid, sushye.stripePriceid)}>Edit</Button>
                                                <div style={{marginTop: 25, paddingLeft: 200}}>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> 3 GB allocation of space</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Hotspots: static, dynamic and linked</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Editable overlays</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Tracking and AI based recognition</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Choice of player representation</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Branching by video and audio</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Shoppable video</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Video analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Product analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Support service</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={cross} alt="cross" style={{height: 15, width: 15}} /> Team</p>
                                                </div>
                                            </CardBody>
                                        ))
                                    }
                                </Card>
                            </Col>
                            <Col md="6" lg="6" xl="5" className="text-center">
                                <Card>
                                    {
                                        this.state.unlimitedYearly.map(suunye => (
                                            <CardBody>
                                                <h3 className="card-title">{suunye.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{suunye.price}/yr</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(suunye.planid, suunye.stripePriceid)}>Edit</Button>
                                                <div style={{marginTop: 25, paddingLeft: 200}}>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Unlimited space</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Hotspots: static, dynamic and linked</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Editable overlays</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Tracking and AI based recognition</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Choice of player representation</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Branching by video and audio</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Shoppable video</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Video analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Product analytics</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Support service</p>
                                                    <p style={{textAlign: "left", fontSize: '14px'}}> <img src={check} alt="check" style={{height: 15, width: 15}} /> Team</p>
                                                </div>
                                            </CardBody>
                                        ))
                                    }
                                    
                                </Card>
                            </Col>

                        </Row>
                        <h5>Storage</h5>
                        <Row className="align-item-center justify-content-center d-flex">
                            <Col md="4" lg="4" xl="4" className="text-center">
                                <Card>
                                    {
                                        this.state.storageYearly1.map(stye1 => (
                                            <CardBody>
                                                <h3 className="card-title">{stye1.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{stye1.price}/yr</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(stye1.planid, stye1.stripePriceid)}>Edit</Button>
                                            </CardBody>
                                        ))
                                    }
                                    

                                </Card>
                            </Col>
                            <Col md="4" lg="4" xl="4" className="text-center">
                                <Card>
                                {
                                        this.state.storageYearly2.map(stye2 => (
                                            <CardBody>
                                                <h3 className="card-title">{stye2.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{stye2.price}/yr</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(stye2.planid, stye2.stripePriceid)}>Edit</Button>
                                            </CardBody>
                                        ))
                                    }
                                </Card>
                            </Col>
                            <Col md="4" lg="4" xl="4" className="text-center">
                                <Card>
                                {
                                        this.state.storageYearly3.map(stye3 => (
                                            <CardBody>
                                                <h3 className="card-title">{stye3.name}</h3><br />
                                                <h5 className="card-subtitle font-14 text-muted">{stye3.price}/yr</h5><br />
                                                <Button color="primary" onClick={() => this.tog_edit(stye3.planid, stye3.stripePriceid)}>Edit</Button>
                                            </CardBody>
                                        ))
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Nav pills justified>
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "5"
                                            })}
                                            onClick={() => {
                                                this.toggle1("5");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Storage</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="waves-effect waves-light">
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab1 === "6"
                                            })}
                                            onClick={() => {
                                                this.toggle1("6");
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                            <span className="d-none d-sm-block">Subscription</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>


                                <TabContent activeTab={this.state.activeTab1}>
                                {this.state.planArchieveAlert ? <Alert style={{marginTop: 10}} color="success">
                                    <strong>Plan archived successfully.</strong>
                                </Alert> : null}
                                {this.state.planArchieveAlertFalse ? <Alert style={{marginTop: 10}} color="danger">
                                    <strong>{this.state.planArchiveErrorMessage}</strong>
                                </Alert> : null}
                                    <TabPane tabId="5" className="p-3">
                                        <Row>
                                            <Col xs="12">
                                                <Button color="primary" onClick={() => this.tog_add_storage()}>Add a storage plan</Button>
                                                <Card>
                                                    <CardBody style={{ cursor: "pointer" }}>
                                                        <MDBDataTable
                                                            responsive
                                                            bordered
                                                            striped
                                                            data={data}
                                                        />

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="6" className="p-3">
                                        <Row>
                                            <Col xs="12">
                                                <Button color="primary" onClick={() => this.tog_add_subscription()}>Add a subcription plan</Button>
                                                <Card>
                                                    <CardBody style={{ cursor: "pointer" }}>
                                                        <MDBDataTable
                                                            responsive
                                                            bordered
                                                            striped
                                                            data={plans}
                                                        />

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>

                                </TabContent>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Editing Form */}
                <Row>
                    <Modal
                        isOpen={this.state.modal_edit}
                        toggle={this.tog_edit}
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">

                        <ModalHeader className="form-modal-header">
                            Edit Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            {this.state.temp ? <Alert color="danger">
                                <strong>Incorrect information. Please provide correct information.</strong>
                            </Alert> : null}
                            <FormGroup row className="formContainer">
                                <Label for="example-text-input" className="col-form-label" style={{padding: "0 0 10px 10px"}}>Stripe Price ID</Label>
                                <Col sm="10">
                                    <Input className="form-control" style={{width: "120%"}} type="text" name="stripePriceId" value={this.state.stripePriceId || ''} onChange={this.changeHandler} id="example-text-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons" onClick={this.tog_edit}>Close</Button>
                            <Button type="button" color="primary" onClick={() => this.updatePlane(this.state.planid, this.state.stripePriceId)} className="waves-effect waves-light">Save Changes</Button>
                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal
                        isOpen={this.state.modal_add_storage}
                        toggle={this.tog_add_storage}
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Add Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <Label for="example-text-input" className="col-form-label"> Price </Label>
                                    <Input className="form-control" type="digit" placeholder="00.00" name="unit_amount" value={this.state.unit_amount} onChange={this.changeHandler} id="example-text-input" />
                                </Col>
                            </FormGroup>

                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Billing Period </Label>
                                <Col sm="12">
                                    <select className="selectFormContainer" name='interval'
                                        value={
                                            this.state.interval
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" hidden disabled>Select</option>
                                        <option value="month">Monthly</option>
                                        <option value="year">Yearly </option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label"> Name </Label>
                                <Col sm="12">
                                    <select className="selectFormContainer" name='nickname'
                                        value={
                                            this.state.nickname
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" hidden disabled>Select</option>
                                        <option value="10GB">10GB</option>
                                        <option value="20GB">20GB </option>
                                        <option value="50GB">50GB </option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons" onClick={this.tog_add_storage}>Close</Button>
                            {
                                this.state.nickname === '' || this.state.interval === '' || this.state.unit_amount === '' ?
                                    <Button type="button" color="primary" disabled onClick={() => this.createStoragePlan()} className="waves-effect waves-light">Save changes</Button>
                                    : <Button type="button" color="primary" onClick={() => this.createStoragePlan()} className="waves-effect waves-light">Save changes</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal
                        isOpen={this.state.modal_add_sub}
                        toggle={this.tog_add_subscription}
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Add Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <Label for="example-text-input" className="col-form-label"> Price </Label>
                                    <Input className="form-control" type="digit" placeholder="00.00" name="unit_amount" value={this.state.unit_amount} onChange={this.changeHandler} id="example-text-input" />
                                </Col>
                            </FormGroup>

                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-sm-6 col-form-label">Billing Period </Label>
                                <Col sm="12">
                                    <select className="selectFormContainer" name='interval'
                                        value={
                                            this.state.interval
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" hidden disabled>Select</option>
                                        <option value="month">Monthly</option>
                                        <option value="year">Yearly </option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label"> Name </Label>
                                <Col sm="12">
                                    <select className="selectFormContainer" name='nickname'
                                        value={
                                            this.state.nickname
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" hidden disabled>Select</option>
                                        <option value="Shoppable">Shoppable</option>
                                        <option value="Unlimited">Unlimited </option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect" onClick={this.tog_add_subscription}>Close</Button>
                            {
                                this.state.nickname === '' || this.state.interval === '' || this.state.unit_amount === '' ?
                                    <Button type="button" disabled color="primary" onClick={() => this.createSubPlan()} className="waves-effect waves-light">Save changes</Button>
                                    : <Button type="button" color="primary" onClick={() => this.createSubPlan()} className="waves-effect waves-light">Save changes</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Subscription);