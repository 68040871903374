import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    FormGroup,
    Input,
    Label,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Moment from "moment"
import { MDBDataTable } from 'mdbreact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Import datatable css
import "../Tables/datatables.scss";

//url
import url from "../../helpers/apiUrl"

class AddFaqCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "Faq", link: "faq" },
                { title: "Add Category", link: "#" },
            ],
            category: [],
            modal_edit: false,
            modal_add: false,
            category_name: '',
            prev_category_name: '',
            category_description: "",
            prev_category_description: "",
            id: '',
            category_status: '',
            prev_category_status: '',
            created_at: '',
            updated_at: '',
            category_id: '',
            success_confirm: false,
            alert_confirm: false,
            temp: false,
            dynamic_title: "",
            dynamic_description: "",
            createFail: false,
            createFaqCat: false,
            updateFail: false,
            updateFaqCat: false
        }
        this.tog_add = this.tog_add.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
        this.viewFaqCategory = this.viewFaqCategory.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.fillCategory = this.fillCategory.bind(this);
        this.updateFaqCategory = this.updateFaqCategory.bind(this);
        this.deleteFaqCategory = this.deleteFaqCategory.bind(this);
        this.createFaqCategory = this.createFaqCategory.bind(this);
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Faq", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.viewFaqCategory();
    }

    viewFaqCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/faq`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                var array = []
                for (let i = 0; i < data.body.length; i++) {
                    array.push({
                        id: data.body[i].id,
                        category_name: data.body[i].category_name,
                        category_description: data.body[i].category_description,
                        category_status: data.body[i].category_status,
                        created_at: Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                        updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                        button:
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button type="button"
                                    onClick={
                                        () => this.fillCategory(data.body[i])
                                    }
                                    style={
                                        { marginRight: 10}
                                    }
                                    id="tooltip_edit_cat_faq"
                                    color="primary"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="ti-pencil"></i>
                                </Button>
                                <UncontrolledTooltip placement="bottom" target="tooltip_edit_cat_faq">
                                    Edit 
                                </UncontrolledTooltip>
                                <Button type="button" color="danger"
                                    onClick={
                                        () => this.setState({category_name: data.body[i].category_name, alert_confirm: true, id: data.body[i].id })
                                    }
                                    className="waves-effect waves-light buttons-secondary"
                                    id="tooltip_delete_cat_faq"><i className="ti-trash"></i>
                                </Button>
                                <UncontrolledTooltip placement="bottom" target="tooltip_delete_cat_faq">
                                    Delete
                                </UncontrolledTooltip>
                            </div>

                    })
                }
                this.setState({ category: array })
            })
            .catch(error => console.log('error', error));

    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    fillCategory = (category) => {
        this.setState({
            id: category.id,
            category_name: category.category_name,
            category_description: category.category_description,
            category_status: category.category_status,
            created_at: category.created_at,
            updated_at: category.updated_at,
            prev_category_name: category.category_name,
            prev_category_description: category.category_description,
            prev_category_status: category.category_status
        })
        this.tog_edit();
    }

    updateFaqCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "category_name": this.state.category_name,
            "category_description": this.state.category_description,
            "category_status": this.state.category_status
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/category/faq/create/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {
                    this.tog_edit()
                    this.viewFaqCategory()
                    this.setState({
                        id: '',
                        category_name: '',
                        category_description: '',
                        category_status: '',
                        //updateFaqCat: true
                    })
                   
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>Category updated successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                }
                else {
                    // this.setState({
                    //     updateFail: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         updateFail: false
                    //     })
                    // }, 3000)
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>Category could not be updated.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }

            })
            .catch(error => console.log('error', error));

    }

    deleteFaqCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/faq/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                //  this.setState({
                //     category: this.state.category.filter(cat => cat.id !== id)
                // })
                toast(<div className='toast-message-container'>
                <h6>FAQ</h6>
                <p>Category deleted successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                this.viewFaqCategory()
            })
            .catch(error => console.log('error', error));

    }

    createFaqCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "category_name": this.state.category_name,
            "category_description": this.state.category_description,
            "category_status": this.state.category_status
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/category/faq/create`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === "200") {
                    this.viewFaqCategory()
                    this.tog_add()

                    this.setState({
                        category_name: '',
                        category_description: '',
                        category_status: '',
                        //createFaqCat: true
                    })
                    // setTimeout(() => {
                    //     this.setState({
                    //         createFaqCat: false
                    //     })
                    // }, 3000)
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>Category created successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                } else {
                    // this.setState({
                    //     createFail: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         createFail: false
                    //     })
                    // }, 3000)
                    toast(<div className='toast-message-container'>
                    <h6>FAQ</h6>
                    <p>Category could not be created.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
            })
            .catch(error => console.log('error', error));

    }

    tog_edit() {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }));
        if (this.state.modal_edit !== false) {
            this.setState({
                category_name: '',
                category_description: '',
                category_status: ''
            })
        }
    }
    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add
        }));
        this.setState({
            category_name: '',
            category_description: '',
            category_status: ''
        })
    }


    render() {

        const data = {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Category Name',
                    field: 'category_name',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Category Description',
                    field: 'category_description',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Category Status',
                    field: 'category_status',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Created At',
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Updated At',
                    field: 'updated_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.category
        };
        return (
            <React.Fragment>
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Category</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete " + this.state.category_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteFaqCategory();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <h3>Faq Category</h3>
                    <Button type="button"
                        style={{background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600}}
                        onClick={
                            () => this.tog_add()
                        }
                        color="info"
                        className="waves-effect waves-light">
                        Add Category</Button>
                </div>

                {this.state.createFaqCat ? <Alert color="success">
                    <strong>Faq category created successfully.</strong>
                </Alert> : null}
                {this.state.updateFaqCat ? <Alert color="success">
                    <strong>Faq category updated successfully.</strong>
                </Alert> : null}
                {this.state.createFail ? <Alert color="danger">
                    <strong>Creation failed.</strong>
                </Alert> : null}
                {this.state.updateFail ? <Alert color="danger">
                    <strong>Updation failed.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    striped
                                    data={data}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_edit
                    }
                        toggle={
                            this.tog_edit
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader>
                            Edit Details
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Id</Label>
                                <Col sm="10">
                                    <Input className="form-control" disabled name="id"
                                        value={
                                            this.state.id
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Category Name (50 characters max.)</Label>
                                <Col sm="12">
                                    <Input className="mb-0 w-100" name="category_name" maxLength="50"
                                        value={
                                            this.state.category_name
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Category Description</Label>
                                <Col sm="12">
                                    <Input className="mb-0 w-100" name="category_description" type="text"
                                        value={
                                            this.state.category_description
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Status</Label>
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='category_status'
                                        value={
                                            this.state.category_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit
                                }>Close</Button>
                                {
                                   this.state.category_name === this.state.prev_category_name && this.state.category_description === this.state.prev_category_description && this.state.category_status === this.state.prev_category_status ?
                                   <Button type="button" color="primary" disabled
                                   style={{cursor: "default"}}
                                        onClick={
                                            () => this.updateFaqCategory()
                                        }
                                        className="waves-effect waves-light">Save changes
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateFaqCategory()
                                        }
                                        className="waves-effect waves-light">Save changes
                                    </Button>
                                }
                                
                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader>
                            Add Details
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Category Name (50 characters max.)</Label>
                                <Col sm="12">
                                    <Input className="w-100" name="category_name" required maxLength="50"
                                        value={
                                            this.state.category_name
                                        }
                                        type="text"
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-tel-input" className="col-form-label">Category Description</Label>
                                <Col sm="12">
                                    <Input className="w-100" name="category_description" type="text" required
                                        value={
                                            this.state.category_description
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Status</Label>
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='category_status'
                                        value={
                                            this.state.category_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add
                                }>Close</Button>
                                {
                                    this.state.category_name === "" || this.state.category_description === "" ||  this.state.category_status === "" ?
                                    <Button type="button" color="primary" disabled
                                        style={{cursor: "default"}}
                                        onClick={
                                            () => this.createFaqCategory()
                                        }
                                        className="waves-effect waves-light">Add Category
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createFaqCategory()
                                        }
                                        className="waves-effect waves-light">Add Category
                                    </Button>
                                }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(AddFaqCategory);