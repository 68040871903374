
import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert,
    Form, 
    Input,
    UncontrolledTooltip
} from "reactstrap";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Moment from "moment"
import { Link } from "react-router-dom";
import url from "../../helpers/apiUrl"
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateTemplate extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, 
                {
                    title: "Template Management",
                    link: "template-managemnet"
                },{
                    title: "Create Template",
                    link: "#"
                },
            ],
            template_content: '',
            template_name: '',
            template_description: '',
            template_type: '',
            template_status: '',
            temp: false,
            texteditor: '',
            templates: [],
            template_name_alert: false,
            template_description_alert: false,
            template_status_alert: false,
            template_type_alert: false,
            template_content_alert: false 
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.createTemplate = this.createTemplate.bind(this);
        this.changeHandlerContent = this.changeHandlerContent.bind(this);
        this.viewTemplates = this.viewTemplates.bind(this);
    }
    

    componentDidMount() {
        this.props.setBreadcrumbItems("Create Template", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        console.log(typeof(this.state.template_content))
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        // this.setState({texteditor});
        //   console.log(this.state.texteditor)
    }
    changeHandlerContent = (value) => {
        this.setState({ template_content: value })
    }


    createTemplate(){
        if(this.state.template_name === ''){
            this.setState({
                template_name_alert: true
            })
            setTimeout(() => {
                this.setState({
                    template_name_alert: false
                })
            }, 3000)
           return;
            
        }
        if(this.state.template_description === ''){
            this.setState({
                template_description_alert: true
            })
            setTimeout(() => {
                this.setState({
                    template_description_alert: false
                })
            }, 3000)
            return;
        }
        if(this.state.template_content === ''){
            this.setState({
                template_content_alert: true
            })
            setTimeout(() => {
                this.setState({
                    template_content_alert: false
                })
            }, 3000)
            return;
        }
        if(this.state.template_status === ''){
            this.setState({
                template_status_alert: true
            })
            setTimeout(() => {
                this.setState({
                    template_status_alert: false
                })
            }, 3000)
            return;
        }
        if(this.state.template_type === ''){
            this.setState({
                template_type_alert: true
            })
            setTimeout(() => {
                this.setState({
                    template_type_alert: false
                })
            }, 3000)
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "template_name": this.state.template_name,
        "template_description": this.state.template_description,
        "template_content": this.state.template_content,
        "template_type": this.state.template_type,
        "template_status": this.state.template_status
        });
        console.log(raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/templates/create/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            if(result.statusCode === "200"){
                this.setState({
                    temp: true,
                    template_content: this.state.template_content,
                    template_description: this.state.template_description,
                    template_name: this.state.template_name,
                    template_status: this.state.template_status,
                    template_type: this.state.template_type
                })
                this.viewTemplates()
                
                setTimeout(() => {
                    this.setState({
                        temp: false,
                        
                    })
                    this.viewTemplates()
                }, 3000);
            } else {
                alert(result)
            }
        })
        .catch(error => console.log('error', error));
    }

    viewTemplates(){
        console.log("World is here")
        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            console.log("from Date", fromDate)
            toDate = Moment().format('YYYY-MM-DD')
            console.log("to date", toDate)
        }

        console.log("kin date", this.state.fromDate, this.state.toDate, new Date())
        if(Moment(this.state.fromDate).format('YYYY-MM-DD') === Moment(new Date()).format('YYYY-MM-DD') && Moment(this.state.toDate).format('YYYY-MM-DD') === Moment(new Date()).format('YYYY-MM-DD')){
            console.log("Hi aryan")
            return;
        }else{
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "template_type": this.state.template_type,
            "template_status": this.state.template_status,
            "from_date": fromDate,
            "to_date": toDate
          });
          console.log("filter temp",raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/templates/view/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var arr = []
            if(result.statusCode === "200"){  
                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                }  
                for(let i =0; i < result.body.length; i++){
                    // console.log(result.body[1].id)
                    arr.push({
                        id: result.body[i].id,
                        template_name: result.body[i].template_name,
                        template_description: result.body[i].template_description,
                        template_type: result.body[i].template_type,
                        template_content: result.body[i].template_content,
                        template_status: result.body[i].template_status,
                        template_created_at: Moment(result.body[i].template_created_at).format('YYYY/MM/DD HH:mm:ss'),
                        templated_updated_at: Moment(result.body[i].templated_updated_at).format('YYYY/MM/DD HH:mm:ss'),
                        button: <div>
                            <Link to={{
                                    pathname: "/update-template",
                                    state: {
                                        template: result.body[i]
                                    }
                                }}>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillTemplate(result.body[i])
                                        }
                                        style={
                                            { marginRight: 10}
                                        }
                                        color="primary" id="tooltip_edit_template"
                                        className="waves-effect waves-light">
                                        <i className="ti-pencil"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_edit_template">
                                        Edit 
                                    </UncontrolledTooltip>
                                </Link>
                            <Button type="button" color="danger"
                                onClick={
                                    () => this.setState({ template_name: result.body[i].template_name, alert_confirm: true, id: result.body[i].id })
                                }
                                className="waves-effect waves-light"
                                id="tooltip_delete_template"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_template">
                                Delete 
                            </UncontrolledTooltip>

                        </div>
                    })
                }
            }
            this.setState({ templates: arr })
            console.log(this.state.templates)
        })
        .catch(error => console.log('error', error));
    }
    }
    
  
    render() {
        console.log(this.state.template_content_alert && this.state.template_description_alert && this.state.template_name_alert && this.state.template_status_alert && this.state.template_type_alert, "link")
        return (
            <React.Fragment>
                {this.state.temp ? <Alert color="success">
                    <strong>Template created successfully.</strong>
                </Alert> : null}
                {this.state.template_name_alert ? <Alert color="danger">
                    <strong>Please provide template name.</strong>
                </Alert> : null}
                {this.state.template_description_alert ? <Alert color="danger">
                    <strong>Please provide template description.</strong>
                </Alert> : null}
                {this.state.template_type_alert ? <Alert color="danger">
                    <strong>Please provide template type.</strong>
                </Alert> : null}
                {this.state.template_status_alert ? <Alert color="danger">
                    <strong>Please provide template status.</strong>
                </Alert> : null}
                {this.state.template_content_alert ? <Alert color="danger">
                    <strong>Please provide template content.</strong>
                </Alert> : null}
                <Row>
                    <Col sm="6" style={{marginTop: "5px"}}>
                        <AvForm className="formContainer">
                        <AvField
                            name="template_name"
                            label="Template Name * (50 characters max.)"
                            maxLength="50"
                            placeholder="Enter Template Name"
                            className="w-100"
                            type="text"
                            errorMessage="Enter Template Name"
                            validate={{ required: { value: true } }}
                            value={
                                this.state.template_name
                            }
                            onChange
                            ={this.changeHandler}
                        />
                        <AvField
                            style={{marginTop: "5px"}}
                            name="template_description"
                            className="w-100"
                            label= {this.state.template_type === "Email" ? "Email Subject *" : "Template Description *"}
                            placeholder="Enter Template Description"
                            type="text"
                            errorMessage="Enter Template Description"
                            validate={{ required: { value: true } }}
                            value={
                                this.state.template_description
                            }
                            onChange
                            ={this.changeHandler}
                        />
                        </AvForm>
                    </Col>
                    <Col sm="6" >
                        <Label for="example-password-input" className="col-form-label"> Template Type *</Label>
                        <FormGroup row className="formContainer">
                            <Col sm="12">
                                <select className="selectFormContainer mb-0 w-100" name='template_type'
                                    value={
                                        this.state.template_type
                                    }
                                    onChange={
                                        this.changeHandler
                                    }>
                                    <option value="" disabled selected hidden>Select</option>
                                    <option value="Email">Email</option>
                                    <option value="SMS">SMS</option>
                                    <option value="Whatsapp">Whatsapp</option>
                                </select>
                            </Col>
                        </FormGroup>
                        <Label for="example-password-input" className="col-form-label"> Status *</Label>
                        <FormGroup row className="formContainer">
                            <Col sm="12">
                                <select className="selectFormContainer mb-0 w-100" name='template_status'
                                    value={
                                        this.state.template_status
                                    }
                                    onChange={
                                        this.changeHandler
                                    }>
                                    <option value="" disabled selected hidden>Select</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Form method="post">
                                    {
                                        this.state.template_type == "Email" ? 
                                        <ReactSummernote
                                            value= {this.state.template_content}
                                            options={{
                                            height: 350,
                                            disableDragAndDrop: true,
                                            toolbar: [
                                                ["style", ["style"]],
                                                ["font", ["bold", "underline", "clear"]],
                                                // ['fontname', ['fontname']],
                                                ["para", ["ul", "ol", "paragraph"]],
                                                ["table", ["table"]],
                                                ["insert", ["link"]],
                                                ["view", [ "codeview"]]
                                            ]
                                            }}
                                            onChange={this.changeHandlerContent}
                                        />
                                        : <Col>
                                        <FormGroup row>
                                            <Col sm="12">
                                               <Input type='textarea' name='template_content' value={this.state.template_content} onChange={this.changeHandler} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    }
                                
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>  
                {/*
                <AvForm>
                    <AvField
                        name="template_content"
                        label="Template Content *"
                        placeholder="Enter Template Content"
                        type="text"
                        errorMessage="Enter Template Content"
                        validate={{ required: { value: true } }}
                        value={
                            this.state.template_content
                        }
                        onChange
                        ={this.changeHandler}
                    /> 
                </AvForm>
                */}
                {
                    this.state.template_content === '' || this.state.template_description === '' || this.state.template_name === '' || this.state.template_status === '' || this.state.template_type === '' ?
                    
                        <Button type="button" color="primary" disabled
                        style={{cursor: "default"}}
                            onClick={
                                () => this.createTemplate()
                            }
                            className="waves-effect waves-light">Add Template
                        </Button>
                    
                    :
                    <Link to="template-managemnet">
                    <Button type="button" color="primary"
                        onClick={
                            () => this.createTemplate()
                        }
                        className="waves-effect waves-light">Add Template
                    </Button>
                </Link>
                }
                
                {/* <Link to="template-managemnet">
                    <Button type="button" color="primary"
                        onClick={
                            () => this.createTemplate()
                        }
                        className="waves-effect waves-light">Add Template
                    </Button>
                </Link> */}
                
                
                {/* <Button type="button" color="primary" 
                style={{marginLeft: 10}}
                    onClick={
                        () => this.createTemplate()
                    }
                    className="waves-effect waves-light">Save Template
                </Button> */}
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(CreateTemplate);


 