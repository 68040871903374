import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    FormGroup,
    Input,
    Label,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from 'mdbreact';

// Import datatable css
import "../Tables/datatables.scss";
import Moment from "moment"

//url
import url from "../../helpers/apiUrl"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddSupportPriority extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "Customer Support", link: "customerservice" },
                { title: "Add Priority", link: "#" },
            ],
            modal_add_pri: false,
            modal_edit_pri: false,
            temp: false,
            pri: [],
            priority: [],
            id: '',
            arr: [],
            priority_name: '',
            priority_description: '',
            priority_status: '',
            success_confirm: false,
            alert_confirm: false,
            dynamic_title: "",
            dynamic_description: "",
            prev_priority_description: '',
            prev_priority_name: '',
            prev_priority_status: '',
            createPriority: false,
            updatePriority: false
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.tog_add_priority = this.tog_add_priority.bind(this);
        this.tog_edit_priority = this.tog_edit_priority.bind(this)
        this.createSupportPriority = this.createSupportPriority.bind(this);
        this.fillPriority = this.fillPriority.bind(this);
        this.vieallSupportPriority = this.vieallSupportPriority.bind(this);
        this.deletePriority = this.deletePriority.bind(this);
        this.updateSupportPriority = this.updateSupportPriority.bind(this)
        this.viewPriorityById = this.viewPriorityById.bind(this)
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Customer Support", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }

        this.vieallSupportPriority()
    }

    fillPriority = (pri) => {
        this.setState({ 
            id: pri.id, 
            priority_name: pri.priority_name, 
            priority_description: pri.priority_description, 
            priority_status: pri.priority_status,
            prev_priority_description: pri.priority_description,
            prev_priority_name: pri.priority_name,
            prev_priority_status: pri.priority_status 
        })

        this.tog_edit_priority();
    }



    createSupportPriority() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "priority_name": this.state.priority_name, "priority_description": this.state.priority_description, "priority_status": this.state.priority_status });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/priority/support/create`, requestOptions).then(response => response.json()).then(result => {
            if (result.statusCode === "200") {
                this.vieallSupportPriority();
                this.tog_add_priority();
                this.setState({ priority_name: '', priority_description: '', priority_status: '', 
                //createPriority: true 
            })
                // setTimeout(() => {
                //     this.setState({
                //         createPriority: false
                //     })
                    
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Ticket Priority</h6>
                <p>Ticket priority created successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }
            if (result.body === "Support Priority already exists") {
                // this.setState({
                //     temp: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         temp: false
                //     })
                // }, 3000);
                toast(<div className='toast-message-container'>
                <h6>Ticket Priority</h6>
                <p>Ticket already exists.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        }).catch(error => console.log('error', error));
    }

    updateSupportPriority() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "priority_name": this.state.priority_name, "priority_description": this.state.priority_description, "priority_status": this.state.priority_status });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/priority/support/create/` + this.state.id, requestOptions).then(response => response.json()).then(result => {
            if (result.statusCode === "200") {
                this.vieallSupportPriority()
                this.tog_edit_priority()
                this.setState({
                    id: '',
                    priority_name: '',
                    priority_description: '',
                    priority_status: '',
                    //updatePriority: true
                })
                // setTimeout(() => {
                //     this.setState({
                //         updatePriority: false
                //     })
                   
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Ticket Priority</h6>
                <p>Ticket priority updated successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }

        }).catch(error => console.log('error', error));
    }

    vieallSupportPriority() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/priority/support`, requestOptions).then(response => response.json()).then(data => {
            if(data.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            for (let i = 0; i < data.body.length; i++) {
                array.push({
                    id: data.body[i].id,
                    priority_name: data.body[i].priority_name,
                    priority_description: data.body[i].priority_description,
                    priority_status: data.body[i].priority_status,
                    created_at: Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                    updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                    button: <div style={{display: "flex", justifyContent: "center"}}>
                        <Button type="button"
                            onClick={
                                () => this.fillPriority(data.body[i])
                            }
                            style={
                                { marginRight: 10 }
                            }
                            color="primary"
                            id="tooltip_edit_pri_ticket"
                            className="waves-effect waves-light buttons-secondary">
                            <i className="ti-pencil"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="tooltip_edit_pri_ticket">
                            Edit 
                        </UncontrolledTooltip>
                        <Button type="button" color="danger"
                            onClick={
                                () => this.setState({ priority_name: data.body[i].priority_name, alert_confirm: true, id: data.body[i].id })
                            }
                            className="waves-effect waves-light buttons-secondary"
                            id="tooltip_delete_pri_ticket"><i className="ti-trash"></i>
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="tooltip_delete_pri_ticket">
                            Delete
                        </UncontrolledTooltip>
                    </div>

                })
            }
            this.setState({ priority: array })
        }).catch(error => console.log('error', error));
    }

    viewPriorityById() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/priority/support/1`, requestOptions).then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    deletePriority() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/priority/support/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.statusCode==="200"){
                    toast(<div className='toast-message-container'>
                    <h6>Ticket Priority</h6>
                    <p>Ticket priority deleted successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                }else{
                    toast(<div className='toast-message-container'>
                    <h6>Ticket Priority</h6>
                    <p>Ticket priority could not be deleted.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                }
                this.vieallSupportPriority()
            }).catch(error => console.log('error', error));
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    tog_add_priority() {
        if (this.state.modal_add_pri) {
            this.setState({
                priority_name: '',
                priority_description: '',
                priority_status: ''
            })
        }
        this.setState(prevState => ({
            modal_add_pri: !prevState.modal_add_pri
        }));
    }

    tog_edit_priority() {
        this.setState(prevState => ({
            modal_edit_pri: !prevState.modal_edit_pri
        }));
        if (this.state.modal_edit_pri !== false) {
            this.setState({
                id: '',
                priority_name: '',
                priority_description: '',
                priority_status: ''
            })
        }
    }


    render() {
        const pri = {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Priority Name',
                    field: 'priority_name',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Priority Description',
                    field: 'priority_description',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Priority Status',
                    field: 'priority_status',
                    sort: 'asc',
                    width: 200
                }, {
                    label: 'Created At',
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                }, {
                    label: 'Updated At',
                    field: 'updated_at',
                    sort: 'asc',
                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.priority
        };

        return (
            <React.Fragment>
                
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Priority</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete " + this.state.priority_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deletePriority();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div>
                    <h4>Support Priority</h4>
                </div>

                <Button type="button"
                    onClick={
                        () => this.tog_add_priority()
                    }
                    style={
                        { marginBottom: 10, background: "#17A0FB", padding: "7px 14px", borderColor: "#17A0FB", fontWeight: 600, margin: "15px" }
                    }
                    color="info"
                    className="waves-effect waves-light">
                    Add Priority
                </Button>
                {this.state.createPriority ? <Alert color="success">
                    <strong>Priority created successfully.</strong>
                </Alert> : null}
                {this.state.updatePriority ? <Alert color="success">
                    <strong>Priority updated successfully.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped
                                    data={pri} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_pri
                    }
                        toggle={
                            this.tog_add_priority
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Add Details
                        </ModalHeader>
                        {this.state.temp ? <Alert color="danger">
                            <strong>Duplicate entry for priority.</strong>
                        </Alert> : null}
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Priority Name (50 characters max.)</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="priority_name" type="text" maxLength="50"
                                        value={
                                            this.state.priority_name
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Priority Description</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="priority_description" type="text"
                                        value={
                                            this.state.priority_description
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Status</Label>
                                <Col sm="10">
                                    <select className="selectFormContainer" style={{width:"120%"}} name='priority_status'
                                        value={
                                            this.state.priority_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add_priority
                                }>Close
                            </Button>
                            {
                                this.state.priority_name === "" || this.state.priority_description === "" || this.state.priority_status === "" ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.createSupportPriority()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Add Priority
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createSupportPriority()
                                        }
                                        className="waves-effect waves-light">Add Priority
                                    </Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_edit_pri
                    }
                        toggle={
                            this.tog_edit_priority
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader className="form-modal-header">
                            Edit Details
                        </ModalHeader>
                        <ModalBody className="form-modal-body">
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Priority Name (50 characters max.)</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="priority_name" type="text" maxLength="50"
                                        value={
                                            this.state.priority_name
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-text-input" className="col-form-label">Priority Description</Label>
                                <Col sm="10">
                                    <Input className="form-control" name="priority_description" type="text"
                                        value={
                                            this.state.priority_description
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="formContainer2">
                                <Label for="example-password-input" className="col-form-label">Status</Label>
                                <Col sm="10">
                                    <select className="selectFormContainer" style={{width: "120%"}} name='priority_status'
                                        value={
                                            this.state.priority_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="form-modal-header">
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit_priority
                                }>Close
                            </Button>
                            {
                                this.state.priority_name === this.state.prev_priority_name && this.state.priority_description === this.state.prev_priority_description && this.state.priority_status === this.state.prev_priority_status ?
                                <Button type="button" color="primary" disabled
                                style={{cursor: "default"}}
                                    onClick={
                                        () => this.updateSupportPriority()
                                    }
                                    className="waves-effect waves-light">Save Changes
                                </Button> :
                                <Button type="button" color="primary"
                                    onClick={
                                        () => this.updateSupportPriority()
                                    }
                                    className="waves-effect waves-light">Save Changes
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(AddSupportPriority);