import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkLogin, clearErrorLogin, clearError } from '../../store/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import logodark from "../../assets/onedash-logo.svg";
import url from "../../helpers/apiUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Pageslogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            successEmail: false,
            failEmail: false,
            message: ''

        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Sign In</h6>
            <p>Invalid credentials.Please try again.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
            });

        }
    }
    
    handleSubmit = async (e, values) => {
        e.persist();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "email": this.state.email, "password": this.state.password });
        fetch(`${url}/adminusers/login`, {
            method: 'POST',
            headers: myHeaders,
            body: raw
        }).then((response) => response.json()).then(data => {

            if (data.status === "Success") {

                localStorage.setItem("token", data.body.accessToken)
                window.location.assign("/dashboard");

            } else {

                this.disableToast();
                this.props.checkLogin(values.email, values.password, this.props.history);

            }

            this.setState({ items: data })
        })
    }
    componentDidMount() {
        if (!localStorage.getItem("token") === null) {
            return (
                window.location.assign("/dashboard")
            );
        }
        const params = new URLSearchParams(window.location.search);
        const verificationStatus = params.get("verification");
        const msgg = params.get("msg");
        

        if(verificationStatus === "success"){
            toast(<div className='toast-message-container'>
            <h6>Verify Email</h6>
            <p>{msgg}</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
            });
        }
        else if(verificationStatus === "fail"){
            toast(<div className='toast-message-container'>
            <h6>Verify Email</h6>
            <p>{msgg}</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
            });
        }
        else{
        }
    }

    render() {

        return (
            <>
                
                <div>
                    <div className='loginCo' style={{ background: "#222227" }}>
                        <img alt="Logo" src={logodark}></img>
                    </div>
                    <div className='loginCo' style={{backgroud:"rgba(38,38,43,255)"}}>
                        <AvForm onValidSubmit={this.handleSubmit}>
                            <div className='header'>Sign in</div>
                            <div>
                                <AvField name="email" placeholder="Email" value={this.state.email} onChange={this.changeHandler} type="email" />
                            </div>
                            <div>
                                <AvField name= 'password' type='password' placeholder='Password' value={this.state.password} onChange={this.changeHandler} />
                            </div>
                            <div><button type='submit' onSubmit={this.handleSubmit}>Sign in</button></div>
                        </AvForm>
                    </div>
                </div>

                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            </>
        );
    }
}

const mapStatetoProps = state => {
    const { user, loginError, loading } = state.Login;
    return { user, loginError, loading };
}




export default withRouter(connect(mapStatetoProps, { checkLogin, clearErrorLogin, clearError })(Pageslogin));